import React, { type PropsWithChildren } from "react";

import { QueryClientProvider } from "@tanstack/react-query";

import coreQueryClient from "core-ui/client/react/core/shared/coreQueryClient";

import { TranslationsServiceProvider } from "./TranslationsServiceProvider";

/**
 * @description LoginQueryClientProvider is a provider that wraps the QueryClientProvider from react-query
 * to be used in the application for our react components/containers. This provider should help maintain context
 * for a number of our services and functions.
 * @returns
 */
const LoginQueryClientProvider = ({ children }: PropsWithChildren<{}>) => {
    return (
        <QueryClientProvider client={coreQueryClient}>
            <TranslationsServiceProvider>{children}</TranslationsServiceProvider>
        </QueryClientProvider>
    );
};

export default LoginQueryClientProvider;
